var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-3",attrs:{"align":"center"}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:500ms",value:(_vm.getItems),expression:"getItems",arg:"500ms"}],attrs:{"label":"ابحث هنا","color":"indigo","hide-details":"","prepend-inner-icon":"search","outlined":"","dense":"","filled":""},model:{value:(_vm.$global.state.filter.search),callback:function ($$v) {_vm.$set(_vm.$global.state.filter, "search", $$v)},expression:"$global.state.filter.search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.complaintsTypes,"loading":_vm.$global.state.loading,"clearable":"","item-text":"name","item-value":"value","color":"indigo","dense":"","label":"النوع","no-data-text":"لا توجد بيانات","hide-details":"","prepend-inner-icon":"fi fi-br-exclamation","outlined":"","filled":""},on:{"input":function($event){return _vm.getItems()}},model:{value:(_vm.$global.state.complaintType),callback:function ($$v) {_vm.$set(_vm.$global.state, "complaintType", $$v)},expression:"$global.state.complaintType"}})],1)],1),_c('v-card',{attrs:{"id":"card"}},[_c('v-data-table',{staticClass:"mytable",attrs:{"items":_vm.$global.state.complaints,"loading":_vm.$global.state.loading,"headers":_vm.headers,"search":_vm.$global.state.filter.search,"hide-default-footer":"","loading-text":"جاري تحميل البيانات","no-data-text":"لا توجد بيانات","id":_vm.$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light',"items-per-page":_vm.$global.state.filter.pageSize},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(new Date(item.created).toISOString().substring(0,10)))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type == 1)?_c('span',{staticClass:"error--text"},[_vm._v("شكوى")]):_vm._e(),(item.type == 2)?_c('span',{staticClass:"green--text"},[_vm._v("اقتراح")]):_vm._e()]}},{key:"item.body",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.body.length > 30 ? item.body.substring(0, 30) + ' ...' : item.body))])]}},{key:"item.replay",fn:function(ref){
var item = ref.item;
return [(item.replay)?_c('div',[_vm._v(_vm._s(item.replay.length > 30 ? item.replay.substring(0, 30) + ' ...' : item.replay))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openEditDialog(item)}}},on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("fa-solid fa-reply")])],1)]}}],null,true)},[_c('span',[_vm._v("رد")])])]}}])})],1),_c('div',{staticClass:"d-flex justify-space-between py-3"},[_c('v-spacer'),_c('Pagination')],1),_c('Dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }